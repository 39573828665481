.spectrum--dark .spectrum-StatusLight {
  color: rgb(227, 227, 227);
}
.spectrum--dark .spectrum-StatusLight[disabled],
.spectrum--dark .spectrum-StatusLight.is-disabled {
    color: rgb(110, 110, 110);
  }
.spectrum--dark .spectrum-StatusLight[disabled]::before,
.spectrum--dark .spectrum-StatusLight.is-disabled::before {
      background-color: rgb(90, 90, 90);
    }
.spectrum--dark .spectrum-StatusLight--negative::before {
  background-color: rgb(227, 72, 80);
}
.spectrum--dark .spectrum-StatusLight--notice::before {
  background-color: rgb(230, 134, 25);
}
.spectrum--dark .spectrum-StatusLight--positive::before {
  background-color: rgb(45, 157, 120);
}
.spectrum--dark .spectrum-StatusLight--info::before,
.spectrum--dark .spectrum-StatusLight--active::before {
  background-color: rgb(38, 128, 235);
}
.spectrum--dark .spectrum-StatusLight--neutral {
  color: rgb(185, 185, 185);
}
.spectrum--dark .spectrum-StatusLight--neutral::before {
    background-color: rgb(110, 110, 110);
  }
.spectrum--dark .spectrum-StatusLight--celery::before {
  background-color: rgb(68, 181, 86);
}
.spectrum--dark .spectrum-StatusLight--yellow::before {
  background-color: rgb(223, 191, 0);
}
.spectrum--dark .spectrum-StatusLight--fuchsia::before {
  background-color: rgb(192, 56, 204);
}
.spectrum--dark .spectrum-StatusLight--indigo::before {
  background-color: rgb(103, 103, 236);
}
.spectrum--dark .spectrum-StatusLight--seafoam::before {
  background-color: rgb(27, 149, 154);
}
.spectrum--dark .spectrum-StatusLight--chartreuse::before {
  background-color: rgb(133, 208, 68);
}
.spectrum--dark .spectrum-StatusLight--magenta::before {
  background-color: rgb(216, 55, 144);
}
.spectrum--dark .spectrum-StatusLight--purple::before {
  background-color: rgb(146, 86, 217);
}
